@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --example: 0 0% 100%;
  }

  .dark {
    --example: 222.2 84% 4.9%;
  }
}

.min-h-100 {
  @include min-height(100vh);
}

.p-0 {
  @include padding(0);
}

.p-1 {
  @include padding(1rem);
}

#__next {
  height: 100%;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #7bda08;
    border-radius: 100vh;
    border: 2px solid #5daf01;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #5daf01;
  }

  .scrollbar::-webkit-scrollbar-corner {
    background: transparent;
  }
}

#project-detail iframe {
  width: 100%;
  height: 200px;
}

#project-detail-description {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 12px;
  }

  ol {
    list-style-type: decimal;
    margin-left: 20px;
  }

  ol li {
    margin-bottom: 8px;
  }

  scrollbar-width: thin;
}

.scroll-w-none {
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.custom-tooltip {
  background-color: #7bda08 !important;
  padding: 5px 10px;
  border-radius: 99px !important;
  color: #1b1b1b;
  font-size: 16px;
}
